<template>
  <div class="image-dialog">
    <el-dialog
      title="编辑 - 图片"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="imageVisible"
      :show-close="false"
    >
      <el-form :model="settingForm" size="medium" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="settingForm.title" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="settingForm.description" />
        </el-form-item>
        <el-form-item label="图片">
          <div v-if="multiple" class="flex flex-wrap">
            <div
              v-for="(item, index) in imagesArr"
              :key="index"
              class="image-container"
            >
              <el-image
                :src="item"
                fit="cover"
                :preview-src-list="imagesArr"
              />
              <el-button size="mini" icon="el-icon-delete" @click="deleteImage(index)" />
            </div>
            <image-upload
              style="width: 100px; height: 100px"
              dir="setting"
              :play-back="false"
              @input="handleUpload"
            />
          </div>
          <div v-else class="flex flex-wrap">
            <el-image
              v-if="settingForm.value"
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="cover"
              :src="settingForm.value"
              :preview-src-list="[settingForm.value]"
            />
            <image-upload
              style="width: 100px; height: 100px"
              dir="setting"
              :play-back="false"
              @input="handleUpload"
            />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ImageDialog',
  props: {
    imageVisible: {
      type: Boolean,
      default: false
    },
    settingForm: {
      type: Object,
      default: () => {}
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      imagesArr: []
    }
  },
  watch: {
    settingForm: {
      handler (val) {
        if (val.value) {
          this.imagesArr = JSON.parse(val.value)
        }
      }
    }
  },
  methods: {
    handleUpload (val) {
      if (this.multiple) {
        this.imagesArr.push(val)
      } else {
        this.$emit('handleUpload', val)
      }
    },
    deleteImage (index) {
      this.imagesArr.splice(index, 1)
    },
    cancel () {
      this.$emit('cancel')
    },
    save () {
      if (this.multiple) {
        this.$emit('save', this.imagesArr)
      } else {
        this.$emit('save')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-dialog {
  .flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .image-container {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    .el-image {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
