<template>
  <div class="video-upload">
    <div class="cover">
      <el-image
        v-if="coverFileList.length"
        style="width: 100px; height: 100px"
        :src="coverFileList[0].url"
        :preview-src-list="[coverFileList[0].url]"
        fit="cover"
      />
      <div class="upload">
        <el-upload
          ref="cover"
          action="cover"
          :limit="1"
          :auto-upload="false"
          :file-list="coverFileList"
          :on-change="handleCoverChange"
          :on-remove="handleCoverRemove"
        >
          <el-button size="small" type="primary">选择封面</el-button>
          <div slot="tip" class="el-upload__tip">上传图片文件</div>
        </el-upload>
      </div>
    </div>
    <el-divider content-position="center">分割线</el-divider>
    <el-upload
      ref="video"
      action="video"
      :limit="1"
      :auto-upload="false"
      :file-list="videoFileList"
      :on-change="handleVideoChange"
      :on-remove="handleVideoRemove"
    >
      <el-button size="small" type="primary">选择视频</el-button>
      <div slot="tip" class="el-upload__tip">上传视频文件</div>
    </el-upload>
    <el-button size="small" class="uploadBtn" @click="submitUpload">上传</el-button>
    <el-progress :text-inside="true" :stroke-width="20" :percentage="progress" />
  </div>
</template>

<script>
import TcVod from 'vod-js-sdk-v6'
import { getVideoSignature } from '@/services/third'
import { uploadVideo } from '@/services/setting'

export default {
  name: 'VideoUploadSetting',
  props: {
    id: {
      type: Number,
      default: 0
    },
    file: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      fileData: this.file || {},
      coverFileList: [],
      videoFileList: [],
      progress: 0
    }
  },
  watch: {
    file: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && Object.keys(val).length) {
          if (val.cover) {
            this.coverFileList = [{ name: 'cover', url: val.cover.url }]
          }
          if (val.video) {
            this.videoFileList = [{ name: 'video', url: val.video.url }]
          }
        } else {
          this.coverFileList = []
          this.videoFileList = []
        }
      }
    }
  },
  methods: {
    handleCoverChange (file) {
      this.fileData.cover = file
    },
    handleVideoChange (file) {
      this.fileData.video = file
    },
    handleCoverRemove (file, fileList) {
      if (this.fileData && Object.keys(this.fileData).length) {
        delete this.fileData.cover
      }
      this.coverFileList = []
    },
    handleVideoRemove (file, fileList) {
      if (this.fileData && Object.keys(this.fileData).length) {
        delete this.fileData.video
      }
      this.videoFileList = []
    },
    async getVideoSignature () {
      const { data } = await getVideoSignature()
      return data
    },
    submitUpload () {
      const tcVod = new TcVod({
        getSignature: this.getVideoSignature
      })
      // 上传
      const params = {}
      if (this.fileData.video) {
        params.mediaFile = this.fileData.video.raw
      }
      if (this.fileData.cover) {
        params.coverFile = this.fileData.cover.raw
      }
      const uploader = tcVod.upload(params)
      uploader.on('media_progress', info => {
        this.progress = parseInt(info.percent * 100)
      })
      // 视频上传完成时
      uploader.on('media_upload', (info) => {
        this.videoDone = true
      })
      // 封面上传完成时
      uploader.on('cover_upload', (info) => {
        this.coverDone = true
      })
      // 结果
      uploader.done().then(async doneResult => {
        await uploadVideo(this.id, {
          video: doneResult
        })
        this.progress = 0
        this.$message({
          type: 'success',
          message: '上传成功'
        })
        this.$emit('finish')
      })
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.video-upload {
  .cover {
    display: flex;
    .el-image {
      margin-right:20px;
    }
    .upload {
      flex: 1;
    }
  }
  .uploadBtn {
    margin-bottom: 15px;
  }
}
</style>
