import request from '@/utils/request'
import qs from 'qs'

// 获取设置列表
export const getSettings = () => {
  return request({
    method: 'GET',
    url: '/settings'
  })
}

// 更新设置
export const updateSetting = (id, data) => {
  return request({
    method: 'PATCH',
    url: `/settings/${id}`,
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data)
      }
    ]
  })
}

// 上传视频
export const uploadVideo = (id, data) => {
  return request({
    method: 'PATCH',
    url: `/settings/${id}/upload-video`,
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data)
      }
    ]
  })
}

// 清空视频
export const emptyVideo = id => {
  return request({
    method: 'PATCH',
    url: `/settings/${id}/empty-video`
  })
}
