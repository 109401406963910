<template>
  <div class="setting">
    <!-- 表格 -->
    <el-table
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" />
      <el-table-column label="标题" prop="title" show-overflow-tooltip />
      <el-table-column label="属性" prop="key" show-overflow-tooltip />
      <el-table-column label="属性值" prop="value" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="showData(scope.row.id)">{{ scope.row.value }}</div>
          <div v-else-if="scope.row.value">
            <el-tag>已设置</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="描述" prop="description" show-overflow-tooltip />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <!-- 分享有效期 -->
    <el-dialog
      title="编辑 - 分享有效期"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="basicVisible"
    >
      <el-form :model="settingForm" size="medium" label-width="60px">
        <el-form-item label="标题">
          <el-input v-model="settingForm.title"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="settingForm.description"></el-input>
        </el-form-item>
        <el-form-item label="属性值">
          <el-input v-model="settingForm.value"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 月嫂主页内容 -->
    <el-dialog
      title="编辑 - 月嫂主页内容"
      width="70%"
      :close-on-click-modal="false"
      :visible.sync="babysitterContentVisible"
    >
      <el-form :model="settingForm" size="medium" label-width="60px">
        <el-form-item label="标题">
          <el-input v-model="settingForm.title" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="settingForm.description" />
        </el-form-item>
        <el-form-item label="内容">
          <quill-editor
            v-model="settingForm.value"
            :options="editorOption"
            ref="myQuillEditor"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 评定组成员 -->
    <el-dialog
      title="编辑 - 评定组成员"
      width="70%"
      :close-on-click-modal="false"
      :visible.sync="remarkByVisible"
    >
      <el-form :model="settingForm" size="medium" label-width="60px">
        <el-form-item label="标题">
          <el-input v-model="settingForm.title" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="settingForm.description" />
        </el-form-item>
        <el-form-item label="成员">
          <div
            v-for="(item, index) in remarkByArr"
            :key="index"
            class="group"
          >
            <image-upload
              :key="item.avatar"
              class="item"
              v-model="item.avatar"
              style="width: 32px; height: 32px;"
              dir="babysitter"
            />
            <el-input class="item" size="small" v-model="item.name" placeholder="输入姓名" />
            <el-input class="item" size="small" v-model="item.position" placeholder="输入职位" />
            <el-button class="item" size="small" @click="removeRemarkBy(index)">删除</el-button>
          </div>
          <el-button size="mini" @click="addRemarkBy">添加一行</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 地址 -->
    <el-dialog
      title="编辑 - 地址"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="addressVisible"
    >
      <el-form :model="settingForm" size="medium" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="settingForm.title" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="settingForm.description" />
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="address.address" />
        </el-form-item>
        <el-form-item label="经度">
          <el-input v-model="address.longitude" />
        </el-form-item>
        <el-form-item label="纬度">
          <el-input v-model="address.latitude" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 页面 (title + banner) -->
    <el-dialog
      title="编辑 - 地址"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="pageVisible"
    >
      <el-form :model="settingForm" size="medium" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="settingForm.title" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="settingForm.description" />
        </el-form-item>
        <el-form-item label="页面标题">
          <el-input v-model="page.title" />
        </el-form-item>
        <el-form-item label="页面图片">
          <div class="flex flex-wrap">
            <el-image
              v-if="page.image"
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="cover"
              :src="page.image"
              :preview-src-list="[page.image]"
            />
            <image-upload
              style="width: 100px; height: 100px"
              dir="setting"
              :play-back="false"
              @input="handlePageUpload"
            />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 模态 - 上传视频 -->
    <el-dialog
      :close-on-click-modal="false"
      title="上传视频"
      :visible.sync="videoFormVisible"
    >
      <el-form :model="settingForm" size="medium">
        <el-form-item label="标题">
          <el-input v-model="settingForm.title" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="settingForm.description" />
        </el-form-item>
        <el-form-item>
          <video-upload
            :id="currentSettingId"
            :file="videoFile"
            @finish="onVideoFinish"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-popover
          placement="top"
          width="160"
          v-model="videoVisible">
          <p>确定要清空已上传的月嫂视频吗?</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="videoVisible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="handleEmptyVideo">确定</el-button>
          </div>
          <el-button size="small" type="danger" slot="reference">清 空</el-button>
        </el-popover>
        <el-button
          size="small"
          type="primary"
          style="margin-left: 10px"
          @click="videoCancel()"
        >确 定</el-button>
      </div>
    </el-dialog>
    <!-- 模态 - 上传图片 -->
    <image-dialog
      :imageVisible="imageVisible"
      :settingForm="settingForm"
      :multiple="imageMultiple"
      @cancel="cancel"
      @handleUpload="handleUpload"
      @save="save"
    />
  </div>
</template>

<script>
import VideoUpload from '@/components/VideoUploadSetting'
import ImageDialog from './components/ImageDialog'
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { getSettings, updateSetting, emptyVideo } from '@/services/setting'

Quill.register('modules/ImageExtend', ImageExtend)

export default {
  name: 'Setting',
  components: {
    quillEditor,
    VideoUpload,
    ImageDialog
  },
  data () {
    return {
      tableData: [],
      settingForm: {
        title: '',
        description: '',
        value: ''
      },
      basicVisible: false,
      babysitterContentVisible: false,
      remarkByVisible: false,
      remarkByArr: [{ avatar: '', name: '', position: '' }],
      editorOption: {
        placeholder: '请输入内容...',
        modules: {
          ImageExtend: {
            loading: true,
            name: 'img',
            action: `${process.env.VUE_APP_SERVER}/api/v1/third/image-to-cos`,
            headers: (xhr) => {
              xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.user.access_token}`)
            },
            response: (res) => {
              return `${process.env.VUE_APP_COS_URL}/${res.key}`
            }
          },
          toolbar: {
            container: container,
            handlers: {
              image () {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      },
      addressVisible: false,
      address: {},
      pageVisible: false,
      page: {},
      videoFormVisible: false,
      videoVisible: false,
      videoFile: {},
      currentSettingId: 0,
      imageVisible: false,
      imageMultiple: false
    }
  },
  created () {
    this.getSettings()
  },
  methods: {
    async getSettings () {
      const { data } = await getSettings()
      this.tableData = data
    },
    handleEdit (index, row) {
      this.settingForm = { ...row }
      switch (row.id) {
        case 1:
        case 4:
          this.basicVisible = true
          break
        case 2:
          this.babysitterContentVisible = true
          break
        case 3:
          this.remarkByVisible = true
          if (row.value) {
            this.remarkByArr = JSON.parse(row.value)
          }
          break
        case 5:
          this.addressVisible = true
          if (row.value) {
            this.address = JSON.parse(row.value)
          }
          break
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
        case 19:
        case 20:
        case 21:
          this.pageVisible = true
          if (row.value) {
            this.page = JSON.parse(row.value)
          }
          break
        case 6:
          this.videoFormVisible = true
          this.currentSettingId = 6
          if (row.value) {
            this.videoFile = JSON.parse(row.value)
          }
          break
        case 7:
        case 8:
        case 10:
        case 11:
        case 12:
        case 13:
        case 22:
        case 23:
        case 24:
        case 25:
          this.imageMultiple = false
          this.imageVisible = true
          break
        case 9:
          this.imageMultiple = true
          this.imageVisible = true
          break
      }
    },
    addRemarkBy () {
      this.remarkByArr.push({ avatar: '', name: '', position: '' })
    },
    removeRemarkBy (index) {
      console.log(index)
      this.remarkByArr.splice(index, 1)
    },
    cancel () {
      this.settingForm = {}
      this.basicVisible = false
      this.babysitterContentVisible = false
      this.remarkByVisible = false
      this.addressVisible = false
      this.imageVisible = false
      this.remarkByArr = [{ avatar: '', name: '', position: '' }]
      this.address = {}
      this.pageVisible = false
      this.page = {}
    },
    // 保存
    async save (obj) {
      const { id, title, description } = this.settingForm
      let { value } = this.settingForm
      // 需要 json 处理的 arr
      if (id === 3) {
        value = JSON.stringify(this.remarkByArr)
      }
      if (id === 5) {
        value = JSON.stringify(this.address)
      }
      if (id === 9) {
        value = JSON.stringify(obj)
      }
      const idArr = [14, 15, 16, 17, 18, 19, 20, 21]
      if (idArr.indexOf(id) !== -1) {
        value = JSON.stringify(this.page)
      }
      await updateSetting(id, { title, description, value })
      this.cancel()
      this.getSettings()
      this.$message({
        type: 'success',
        message: '更新成功'
      })
    },
    // 是否展示 value
    showData (id) {
      const arr = [1, 4]
      if (arr.indexOf(id) === -1) {
        return false
      }
      return true
    },
    onVideoFinish () {
      this.currentSettingId = 0
      this.videoFile = {}
      this.videoFormVisible = false
      this.getSettings()
    },
    videoCancel () {
      this.currentSettingId = 0
      this.videoFile = {}
      this.videoFormVisible = false
    },
    async handleEmptyVideo () {
      await emptyVideo(this.currentSettingId)
      this.videoVisible = false
      this.videoFormVisible = false
      this.videoFile = {}
      this.getSettings()
      this.$message({
        type: 'success',
        message: '清除成功'
      })
    },
    handleUpload (val) {
      if (!this.imageMultiple) {
        this.settingForm.value = val
      }
    },
    handlePageUpload (val) {
      this.$set(this.page, 'image', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.setting {
  box-sizing: border-box;
  padding: 15px;
  .flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .group {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .item {
      margin-right: 10px;
    }
    .el-input {
      width: 200px;
    }
  }
  .el-dialog {
    .content {
      display: flex;
      flex-wrap: wrap;
      .preview-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 5px;
        margin-bottom: 5px;
        width: 50px;
      }
    }
    .main {
      display: flex;
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:first-child {
          margin-right: 15px;
        }
      }
    }
    .job-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      width: 100px;
      height: 140px;
      .el-image {
        margin-bottom: 5px;
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
